define("discourse/plugins/z-communiteq-features/discourse/components/show-backup-info", ["exports", "@ember/component", "@glimmer/component", "@ember/service", "@ember/template-factory"], function (_exports, _component, _component2, _service, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div style="padding: 12px; background-color: #d1f0ff; color: #222;">
  Off-site backups for disaster recovery are created every 24 hours. This page only shows the backups you have made yourself.
  </div>
  {{#if this.showMigrateInfo}}
  <div style="padding: 12px; background-color: #fffbd6; color: #222;">
  <b>Important: </b>If you are migrating from another hosting provider then we recommend you to read
  <a href="https://www.communiteq.com/discoursehosting/kb/migrate-from-another-provider/" target="_blank">our migration guide</a> before
  attempting to restore a backup.
  </div>
  {{/if}}
  
  */
  {
    "id": "kGvCmqKM",
    "block": "[[[10,0],[14,5,\"padding: 12px; background-color: #d1f0ff; color: #222;\"],[12],[1,\"\\nOff-site backups for disaster recovery are created every 24 hours. This page only shows the backups you have made yourself.\\n\"],[13],[1,\"\\n\"],[41,[30,0,[\"showMigrateInfo\"]],[[[10,0],[14,5,\"padding: 12px; background-color: #fffbd6; color: #222;\"],[12],[1,\"\\n\"],[10,\"b\"],[12],[1,\"Important: \"],[13],[1,\"If you are migrating from another hosting provider then we recommend you to read\\n\"],[10,3],[14,6,\"https://www.communiteq.com/discoursehosting/kb/migrate-from-another-provider/\"],[14,\"target\",\"_blank\"],[12],[1,\"our migration guide\"],[13],[1,\" before\\nattempting to restore a backup.\\n\"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\"]]",
    "moduleName": "discourse/plugins/z-communiteq-features/discourse/components/show-backup-info.hbs",
    "isStrictMode": false
  });
  const FEATURED_CLASS = "show-backup-info";
  let ShowBackupInfo = _exports.default = (_class = class ShowBackupInfo extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "siteSettings", _descriptor, this);
    }
    get showMigrateInfo() {
      return this.siteSettings.bootstrap_mode_enabled;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ShowBackupInfo);
});